// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---pages-404-tsx": () => import("./../pages/404.tsx" /* webpackChunkName: "component---pages-404-tsx" */),
  "component---pages-about-tsx": () => import("./../pages/about.tsx" /* webpackChunkName: "component---pages-about-tsx" */),
  "component---pages-all-tsx": () => import("./../pages/all.tsx" /* webpackChunkName: "component---pages-all-tsx" */),
  "component---pages-drafts-tsx": () => import("./../pages/drafts.tsx" /* webpackChunkName: "component---pages-drafts-tsx" */),
  "component---pages-index-tsx": () => import("./../pages/index.tsx" /* webpackChunkName: "component---pages-index-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */)
}

